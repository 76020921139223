import { BREAKPOINTS, COLORS } from '../../../utils/theme';

import NetworkBrush from '../../../images/network-brush.png';
import styled from 'styled-components';
import { MEMBER_TYPES } from '../../../utils/constants';

const NODE_SIZE = 10;

export const cyStyles = [
  {
    selector: 'node[fullName]',
    style: {
      shape: 'ellipse',
      width: NODE_SIZE,
      height: NODE_SIZE,
      'border-color': COLORS.BACKGROUND_LIGHT,
      'border-width': '0.35px',
      'z-index': 4,
      label: 'data(fullName)',
      'font-size': 2,
      'font-family': 'Hellix, ui-sans-serif, system-ui, sans-serif',
      'font-weight': 400,
      color: COLORS.BLACK,
      'text-halign': 'center',
      'text-valign': 'bottom',
      'text-background-shape': 'round-rectangle',
      'text-background-opacity': 1,
      'text-background-padding': '0.45px',
      'text-background-color': COLORS.WHITE,
      'text-margin-y': '1.15px',
      'text-wrap': 'wrap',
      'text-max-width': '12px',
      'min-zoomed-font-size': '22px',
      'text-events': 'yes',
    },
  },

  {
    selector: 'node[color]',
    style: {
      'background-color': (ele) => {
        return COLORS[ele.data('color')];
      },
    },
  },
  {
    selector: 'node[typeOfMember]',
    style: {
      shape: (ele) => {
        return ele.data('typeOfMember.label') === MEMBER_TYPES.INDIVIDUAL ? 'ellipse' : 'hexagon';
      },
      width: (ele) => {
        return ele.data('typeOfMember.label') === MEMBER_TYPES.INDIVIDUAL
          ? NODE_SIZE
          : NODE_SIZE * 1.15;
      },
    },
  },
  {
    selector: 'node[image.url].show-img',
    style: {
      'background-repeat': 'no-repeat',
      'background-image': function (ele) {
        return ele.data('processedImage.formats.small.url') || ele.data('processedImage.url');
      },
      'background-fit': 'cover',
      'background-image-opacity': 1,
      'border-color': function (ele) {
        return COLORS[ele.data('color')];
      },
    },
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'straight',
      'line-color': COLORS.BLACK,
      width: 0.22,
      events: 'no',
      'z-index': 3,
      opacity: 0.3,
    },
  },
  {
    selector: 'edge.connections',
    style: {
      'line-color': COLORS.BLACK,
      opacity: 1,
      // 'target-arrow-color': COLORS.BLACK,
      // 'target-arrow-shape': 'chevron',
      // 'arrow-scale': 0.15,
    },
  },
  {
    selector: '*',
    style: {
      'overlay-opacity': 0,
    },
  },
  {
    selector: 'core',
    style: {
      'active-bg-opacity': 0,
    },
  },
  {
    selector: 'node:selected',
    style: {
      'border-color': COLORS.BLACK,
    },
  },
  {
    selector: 'node.semitransp',
    style: {
      'background-color': '#f2eee9',
      'z-index-compare': 'manual',
      'z-compound-depth': 'bottom',
      'z-index': 2,
      color: '#f2eee9',
      'background-image-opacity': 0.05,
      'border-color': COLORS.BACKGROUND_LIGHT,
    },
  },
  {
    selector: 'edge.semitransp',
    style: {
      'line-color': '#f2eee9',
      'z-index-compare': 'manual',
      'z-compound-depth': 'bottom',
      'z-index': 1,
    },
  },
  {
    selector: 'node#imgHighlight',
    style: {
      'background-image': `url("${NetworkBrush}")`,
      'background-fit': 'contain',
      'background-image-opacity': 1,
      'background-clip': 'none',
      'background-opacity': 0,
      'z-index': 5,
      width: NODE_SIZE * 1.8,
      height: NODE_SIZE * 1.8,
      events: 'no',
    },
  },
  {
    selector: 'node#center',
    style: {
      events: 'no',
      visibility: 'hidden',
    },
  },
];

export const BottomFixedZone = styled.div`
  isolation: isolate;
  position: fixed;
  bottom: 0;
  right: 0;

  width: 100%;

  pointer-events: none;
  & > * {
    pointer-events: auto;
  }

  display: flex;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    justify-content: center;
  }

  z-index: 15;
`;
