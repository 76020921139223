import React from 'react';

import IconTarget from '../../../assets/target.svg';
import { NewButton } from '../../NewButton';

const YouTarget = ({ cy, userId }) => {
  const findMe = () => {
    const elem = cy.filter(`[id = "${userId}"]`);
    if (!elem.length) return;

    cy.zoom(11);
    cy.center(elem);
  };

  return (
    <NewButton variant="tertiary" size="small" type="you" onClick={findMe}>
      <IconTarget style={{ marginRight: '7px' }}></IconTarget>
      <span>You</span>
    </NewButton>
  );
};

export default YouTarget;
