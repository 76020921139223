import React from 'react';

import ZoomControls from '../ZoomControls';
import YouTarget from '../YouTarget';
import TypeProfilesFilters from '../TypeProfilesFilters';

import * as Styled from './styles';

const BottomToolbar = ({
  showIndividualsFilter,
  showOrganizationsFilter,
  cy,
  userData,
  ZOOM_SETTINGS,
  selectedUserId,
  selectionState,
  isUserVisible,
  selectedOptionsLength,
}) => {
  return (
    <Styled.GlobalContainer>
      <Styled.ButtonsContainer>
        <div style={{ display: 'flex', gap: '6px' }}>
          <TypeProfilesFilters
            showIndividualsFilter={showIndividualsFilter}
            showOrganizationsFilter={showOrganizationsFilter}
          ></TypeProfilesFilters>
        </div>
        <ZoomControls
          cy={cy}
          ZOOM_SETTINGS={ZOOM_SETTINGS}
          selectedOptionsLength={selectedOptionsLength}
        ></ZoomControls>

        {userData?.id && userData?.member && userData?.published && isUserVisible ? (
          <YouTarget cy={cy} userId={userData?.id}></YouTarget>
        ) : null}
      </Styled.ButtonsContainer>

      {/* {selectedUserId && selectionState === 'selected' && (
        <Styled.ArrowsExplanation>
          The direction of the arrows indicates which member stated admiration for the other one, or
          if the admiration is reciprocal.
        </Styled.ArrowsExplanation>
      )} */}
    </Styled.GlobalContainer>
  );
};

export default BottomToolbar;
