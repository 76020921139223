import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

import { BaseButton } from '../BaseButton';

export const ZoomContainer = styled.div`
  display: inline-flex;
  align-items: center;

  position: relative;
  z-index: 20;
  background: ${COLORS.BLACK};
  border-radius: 9999px;
  color: ${COLORS.WHITE};
  padding: 4px 18px;
  gap: 6px;

  & > * {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const ZoomButton = styled(BaseButton)`
  background: transparent;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid rgba(255, 255, 255, 0);

  transition: background 0.2s ease, border-color 0.2s ease;

  &:hover,
  &:focus-visible {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:active {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &:focus-visible {
    outline: 1.5px solid white;
  }
`;

export const ZoomNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2.5px;
  opacity: 0.6;
  font-size: 14px;
`;
