import React, { useState, useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';

import { useSelector } from '@xstate/react';

import BottomToolbar from '../BottomToolbar';
import ProfileExpandedModal from '../ProfileExpandedModal';
import ProfileSummaryModal from '../ProfileSummaryModal';

import { NewButton } from '../../NewButton';
import { resetZoom } from '../../../lib/utils';

import { useLocation } from '@reach/router';
import { cyStyles } from './styles';
import * as Styled from './styles';
import { PreviewImageLoadingSpinner as StyledSpinner } from '../../NewRegisterForm/StepInputFile/styles';

const ZOOM_SETTINGS = {
  MIN: 0.25,
  MAX: 11,
  INITIAL: 5,
  INITIAL_ANIMATION_END: 0.33,
};

const MapView = ({
  users,
  selectedUserId,
  onSelectUser,
  showIndividualsFilter,
  showOrganizationsFilter,
  basicSelectedUserData,
  selectedUserDataState,
  selectedUserData,
  filteredUsers,
  networkErrorMessage,
  totalUsersCount,
  userData,
  noMatches,
  resetTopAndBottomFilters,
  selectedOptionsLength,
  pageMachineActor,
}) => {
  const cyEl = useRef(null);
  const [cy, setCy] = useState(null);

  const [selectionState, setSelectionState] = useState('idle'); // 'idle' | 'hovered' | 'selected' | 'expanded'

  const [selectedCyNode, setSelectedCyNode] = useState();

  const [showImages, setShowImages] = useState();

  const [isUserVisible, setIsUserVisible] = useState(false);

  const location = useLocation();

  const isStateShowingAnimating = useSelector(pageMachineActor, (state) =>
    state.matches('showing.animating'),
  );

  useEffect(() => {
    setIsUserVisible(users?.nodes?.some((node) => Number(node?.data?.id) === userData?.id));
  }, [users, userData]);

  useEffect(() => {
    if (!users?.nodes?.length) return;

    const newCy = cytoscape({
      container: cyEl.current,
      autoungrabify: true,
      autolock: true,
      boxSelectionEnabled: false,
      selectionType: 'single',
      minZoom: ZOOM_SETTINGS.MIN,
      maxZoom: ZOOM_SETTINGS.MAX,
      layout: {
        name: 'preset',
        zoom: ZOOM_SETTINGS.INITIAL,
      },
      style: cyStyles,
      // hideEdgesOnViewport: true,
      elements: users,
    });

    const CAN_PROFILE_ID = 3;
    const toCenterElPosition = newCy.getElementById(String(CAN_PROFILE_ID)).position();
    newCy.add({
      group: 'nodes',
      data: { id: 'center' },
      position: { x: toCenterElPosition.x, y: toCenterElPosition.y },
    });

    const nodeToCenter = newCy.getElementById('center');
    newCy.center(nodeToCenter);

    newCy.nodes().panify().ungrabify();

    setCy(newCy);

    onChangeFilters(newCy);
  }, [users]);

  useEffect(() => {
    if (isStateShowingAnimating) {
      initialNetworkZoomOut(cy);
    }
  }, [isStateShowingAnimating]);

  const initialNetworkZoomOut = (cyEl) => {
    const nodeToCenter = cyEl?.getElementById('center');
    cyEl?.delay(500)?.animate({
      zoom: {
        level: ZOOM_SETTINGS.INITIAL_ANIMATION_END,
        position: nodeToCenter.position(),
      },
      easing: 'ease-out-expo',
      duration: 2000,
    });
  };

  const resetUI = () => {
    if (!cy) return;

    cy.elements().removeClass(['semitransp', 'connections']);
    cy.remove('#imgHighlight');
  };

  const updateUI = (node) => {
    if (!cy || !node) return;

    const nodes = node.incomers().add(node.outgoers());
    nodes.addClass('connections');

    cy.elements().subtract(node).subtract(nodes).addClass('semitransp');

    const nodePosition = node.position();
    cy.add({
      group: 'nodes',
      data: { id: 'imgHighlight' },
      position: { x: nodePosition.x + 0.4, y: nodePosition.y + 1.2 },
    });
  };

  const onTap = (event) => {
    const node = event.target;
    const nodeUserId = node?.id?.();

    onSelectUser(nodeUserId);

    if (nodeUserId) {
      setSelectedCyNode(node);
      setSelectionState('selected');
      // TODO move focus to ProfileSummaryModal
    } else {
      setSelectedCyNode(null);
      setSelectionState('idle');
    }
  };

  const onMouseOver = (event) => {
    if (selectionState !== 'idle') return;

    const node = event?.target;
    const nodeUserId = node?.id?.();
    onSelectUser(nodeUserId);
    setSelectionState('hovered');
  };

  const onMouseOut = () => {
    if (selectionState !== 'hovered') return;
    onSelectUser(null);
    setSelectionState('idle');
  };

  const onZoom = () => {
    if (cy?.zoom() < 6) {
      setShowImages(false);
    } else {
      setShowImages(true);
    }
  };

  useEffect(() => {
    if (!cy) return;

    cy.on('tap', onTap);
    cy.nodes().on('mouseover', onMouseOver);
    cy.nodes().on('mouseout', onMouseOut);
    cy.on('zoom', onZoom);

    return () => {
      cy.off('tap', onTap);
      cy.nodes().off('mouseover', onMouseOver);
      cy.nodes().off('mouseout', onMouseOut);
      cy.off('zoom', onZoom);
    };
  }, [cy, selectionState]);

  useEffect(() => {
    if (!cy) return;

    if (showImages) {
      cy.elements().addClass('show-img');
    } else {
      cy.elements().removeClass('show-img');
    }
  }, [showImages]);

  useEffect(() => {
    if (!cy) return;

    resetUI();
    if (selectedCyNode) {
      updateUI(selectedCyNode);
    }
  }, [selectedCyNode]);

  const onChangeFilters = (cy, filteredUsers) => {
    const filteredUsersIds = filteredUsers?.map((user) => user.data.id) || [];
    if (cy) {
      const elements = cy
        .nodes()
        .subtract(cy.getElementById('imgHighlight'))
        .subtract(cy.getElementById('center'));
      elements.show();
      elements
        .filter((element) => {
          const id = element.data('id');
          return !filteredUsersIds.includes(id);
        })
        .hide();
    }
  };

  useEffect(() => {
    onChangeFilters(cy, filteredUsers);
  }, [cy, location.search]);

  useEffect(() => {
    // TODO Only call this on click "Find matches" in matchmaker modal
    resetZoom(cy, ZOOM_SETTINGS, selectedOptionsLength ? 'fit' : 'initial');
  }, [location.search]);

  useEffect(() => {
    if (selectedUserId === null && selectedCyNode) {
      selectedCyNode?.unselect();
      setSelectedCyNode(null);
      setSelectionState('idle');
    }
  }, [selectedUserId]);

  return (
    <>
      {!filteredUsers?.length || !totalUsersCount ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateY(-50%) translateX(-50%)',
            textAlign: 'center',
            zIndex: 12,
          }}
        >
          {networkErrorMessage === 'Loading data' ? (
            <StyledSpinner>
              <p className="sr-only">{networkErrorMessage}</p>
            </StyledSpinner>
          ) : (
            <p>{networkErrorMessage}</p>
          )}
          {noMatches && (
            <NewButton
              style={{ margin: '0 auto' }}
              variant="primary"
              size="small"
              onClick={resetTopAndBottomFilters}
            >
              Clear all filters
            </NewButton>
          )}
        </div>
      ) : null}
      <div id="cy" ref={cyEl}></div>
      <Styled.BottomFixedZone>
        <BottomToolbar
          showIndividualsFilter={showIndividualsFilter}
          showOrganizationsFilter={showOrganizationsFilter}
          selectedNode={selectedCyNode}
          cy={cy}
          userData={userData}
          ZOOM_SETTINGS={ZOOM_SETTINGS}
          selectedUserId={selectedUserId}
          selectionState={selectionState}
          isUserVisible={isUserVisible}
          selectedOptionsLength={selectedOptionsLength}
        ></BottomToolbar>

        {selectedUserId && selectedUserDataState !== 'idle' && (
          <ProfileSummaryModal
            basicUserData={basicSelectedUserData}
            userDataState={selectedUserDataState}
            userData={selectedUserData}
            onExpand={() => setSelectionState('expanded')}
            isSelected={selectionState === 'selected' || selectionState === 'expanded'}
            onSelectUser={onSelectUser}
          ></ProfileSummaryModal>
        )}

        {/* THis should open if loading data and we are not in search mode */}

        <ProfileExpandedModal
          userData={selectionState === 'expanded' && selectedUserData}
          onClose={() => setSelectionState('selected')}
          onSelectUser={(userId) => {
            const elem = cy.filter(`[id = "${userId}"]`);
            cy.center(elem);

            setSelectedCyNode(elem);
            setSelectionState('selected');

            onSelectUser(userId);
          }}
        />
      </Styled.BottomFixedZone>
    </>
  );
};

export default MapView;
