import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/theme';
import { BaseButton } from '../BaseButton';

export const DialogContainer = styled.div`
  color: ${COLORS.WHITE};
  background: ${COLORS.BLACK};

  position: fixed;
  isolation: isolate;
  z-index: 30;
  bottom: 0;
  left: 0;
  color: white;
  width: 100%;
  max-width: 320px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: none;
  }

  @keyframes loader {
    100% {
      opacity: 0.15;
    }
  }

  & .loading-skeleton {
    height: 1.48rem;
    width: 100%;
    border-radius: 8px;
    position: relative;

    background: ${COLORS.WHITE};

    opacity: 0.09;

    animation: loader 2s ease infinite;
  }
`;

export const UserContainer = styled.div`
  padding-block: 16px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: row-reverse;
    max-width: none;
    justify-content: center;
    column-gap: 50px;
    width: 100%;
  }

  & * {
    margin: 0;
    padding: 0;
  }
`;

export const ViewMoreBtn = styled(BaseButton)`
  width: 44px;
  height: 44px;

  position: absolute;
  right: 16px;
  top: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid rgba(255, 255, 255, 0.68);
  border-radius: 999px;
  background: ${COLORS.BLACK};

  transition: background 0.2s ease, border-color 0.2s ease;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    position: unset;
    width: fit-content;
    height: auto;
    padding: 14px 28px 14px 24px;
    margin: 0 auto;
    margin-top: 16px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.8);
  }

  &:focus-visible {
    outline: 2px solid currentColor;
  }
`;

export const ViewMoreBtnText = styled.span`
  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  z-index: 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}) {
    align-items: center;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0px;

    ${(props) => {
      if (props.hideOnMobile) {
        return `
          display: none;
        `;
      }
    }}
  }
`;

export const Name = styled.h2`
  /* Headings/H4 */
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  max-width: 16ch;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: 14ch;
    text-align: center;
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 220px;
  height: 220px;
  object-fit: cover;
  aspect-ratio: 1/1;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: ${(props) => (props.imagesOfWork ? 'none' : 'block')};
    width: 100px;
    height: 100px;
    border-radius: 6px;
  }

  &[data-image-contain-size='true'] {
    border-radius: 4px;
    border: 2px solid;
    object-fit: contain;

    ${(props) => props.color && `border-color: ${COLORS[props.color]};`};
  }
`;

export const Description = styled.p`
  /* Paragraphs/Small */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.16px;

  overflow: hidden;
  display: -webkit-box;
  --lines-to-show: 6;
  -webkit-line-clamp: var(--lines-to-show);
  line-clamp: var(--lines-to-show);
  -webkit-box-orient: vertical;
`;

export const ProfileNamesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;

  & > li + li {
    margin-top: 0.25rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  column-gap: 2px;
  overflow-y: auto;
  z-index: 2;
`;

export const ProfileTags = styled.p`
  width: 100%;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: center;
  }
`;

export const DottedImage = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 200px;
  height: auto;
  z-index: -1;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: -100px;
    left: -250px;
    width: 250px;
  }
`;

export const ArrowsExplanation = styled.p`
  position: absolute;
  top: -50px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 500;
  background: ${COLORS.WHITE};
  padding-inline: 10px;
  padding-top: 4px;
  padding-bottom: 6px;
  color: ${COLORS.BLACK};
  display: none;
  max-width: 360px;
  margin-inline: 20px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: flex;
  }
`;
