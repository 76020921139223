import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const GlobalContainer = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-left: 348px;
  padding-bottom: 16px;
  padding-right: 16px;
  column-gap: 6px;

  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: column-reverse;
    row-gap: 0px;
    padding-left: 0px;
    text-align: right;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: center;
    align-items: center;
    row-gap: 100px;
    flex-direction: column-reverse;
    padding-left: 0px;
  }
`;

export const ButtonsContainer = styled.div`
  display: inline-flex;
  gap: 6px;
  padding-top: 16px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column-reverse;
  }
`;

export const ArrowsExplanation = styled.p`
  margin: 0;
  max-width: 362px;
  font-size: 12px;
  font-weight: 500;
  background: ${COLORS.WHITE};
  padding-inline: 10px;
  padding-top: 4px;
  padding-bottom: 6px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;
