import React from 'react';

import * as Styled from './styles';

import IconPlus from '../../../assets/plus.svg';

const ProfileSummaryModal = ({ basicUserData, userDataState, userData, onExpand, isSelected }) => {
  return (
    <Styled.DialogContainer>
      {/* {isSelected && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Styled.ArrowsExplanation>
            The direction of the arrows indicates which member stated admiration for the other one,
            or if the admiration is reciprocal.
          </Styled.ArrowsExplanation>
        </div>
      )} */}

      <Styled.UserContainer>
        <Styled.HeaderContainer>
          <Styled.ViewMoreBtn
            onClick={onExpand}
            style={{
              visibility: isSelected ? 'visible' : 'hidden',
              zIndex: 3,
            }}
          >
            <IconPlus aria-hidden="true"></IconPlus>
            <Styled.ViewMoreBtnText aria-hidden="true">View more</Styled.ViewMoreBtnText>
            <span className="sr-only">View more</span>
          </Styled.ViewMoreBtn>

          <Styled.ContentContainer style={{ gap: '4px', zIndex: 2 }}>
            {basicUserData?.fullName ? (
              <Styled.Name>{basicUserData?.fullName}</Styled.Name>
            ) : (
              <div className="loading-skeleton" style={{ height: '2.5rem' }}></div>
            )}

            {userData?.individualProfiles?.length || userData?.organizationType?.length ? (
              userData?.individualProfiles?.length ? (
                <Styled.ProfileTags>
                  {userData?.individualProfiles?.map((profile) => profile.name).join(', ')}
                </Styled.ProfileTags>
              ) : (
                <Styled.ProfileTags>
                  {userData?.organizationType?.map((profile) => profile.name).join(', ')}
                </Styled.ProfileTags>
              )
            ) : null}
          </Styled.ContentContainer>

          <Styled.DottedImage
            src="/images/dotted-blue.png"
            alt=""
            width="917"
            height="1074"
            style={{ zIndex: 1 }}
          ></Styled.DottedImage>
        </Styled.HeaderContainer>

        {userDataState === 'loading' && (
          <Styled.ContentContainer style={{ paddingTop: '30px', gap: '10px' }}>
            <div className="loading-skeleton"></div>
            <div className="loading-skeleton"></div>
            <div className="loading-skeleton"></div>
            <div className="loading-skeleton"></div>
          </Styled.ContentContainer>
        )}
        {userData?.image || userData?.imagesOfWork?.length ? (
          <Styled.ImageContainer>
            {userData?.image && (
              <Styled.Image
                data-image-contain-size={!!userData?.imageContainSize}
                color={userData?.color}
                src={userData?.image?.formats?.small?.url || userData?.image?.url}
                alt={userData?.image?.alternativeText || ''}
                width={userData?.image?.width}
                height={userData?.image?.height}
                loading="lazy"
                decoding="async"
              />
            )}

            {userData?.imagesOfWork?.map((image) => {
              return (
                <Styled.Image
                  key={image.id}
                  data-image-contain-size={!!userData?.imageContainSize}
                  src={image?.formats?.small?.url || image?.url}
                  alt={image?.alternativeText || ''}
                  width={image?.width}
                  height={image?.height}
                  loading="lazy"
                  decoding="async"
                  imagesOfWork={true}
                />
              );
            })}
          </Styled.ImageContainer>
        ) : null}
        {userData?.summary && (
          <Styled.ContentContainer hideOnMobile={true} style={{ zIndex: 2 }}>
            <Styled.Description style={{ marginTop: '-3px' }}>
              {userData?.summary}
            </Styled.Description>
          </Styled.ContentContainer>
        )}
      </Styled.UserContainer>
    </Styled.DialogContainer>
  );
};

export default ProfileSummaryModal;
