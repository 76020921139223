import React, { useEffect, useState } from 'react';

import * as Styled from './styles';

import IconPlus from '../../../assets/plus.svg';
import IconMinus from '../../../assets/minus.svg';

import { resetZoom } from '../../../lib/utils';

const ZoomControls = (props) => {
  function getZoomPercentage(zoomValue) {
    return Math.round(
      ((zoomValue - props.ZOOM_SETTINGS.MIN) /
        (props.ZOOM_SETTINGS.MAX - props.ZOOM_SETTINGS.MIN)) *
        100,
    );
  }

  const [zoomPercentage, setZoomPercentage] = useState(
    getZoomPercentage(props.ZOOM_SETTINGS.INITIAL),
  );

  useEffect(() => {
    if (props.cy) {
      props.cy.on('zoom', () => {
        const zoomPercentage = getZoomPercentage(props.cy.zoom());
        setZoomPercentage(zoomPercentage);
      });
    }
  }, [props.cy]);

  function updateZoom(newZoom) {
    // cy.zoom({
    //   level: newZoom,
    //   renderedPosition: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    // });

    props.cy?.stop();

    props.cy?.animate({
      zoom: {
        level: newZoom,
        renderedPosition: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
      },
      easing: 'ease-out',
    });
  }

  function tryZoomIn() {
    const currentZoom = props.cy?.zoom();
    const newZoom = Math.min(props.ZOOM_SETTINGS.MAX, currentZoom + 1);

    if (currentZoom !== newZoom) updateZoom(newZoom);
  }

  function tryZoomOut() {
    const currentZoom = props.cy?.zoom();
    const newZoom = Math.max(props.ZOOM_SETTINGS.MIN, currentZoom - 1);

    if (currentZoom !== newZoom) updateZoom(newZoom);
  }

  return (
    <Styled.ZoomContainer>
      <Styled.ZoomButton
        data-tooltip-id="info-tooltip"
        data-tooltip-content="Zoom in"
        data-tooltip-place="top"
        onClick={tryZoomIn}
      >
        <IconPlus aria-hidden="true"></IconPlus>
        <span className="sr-only">Zoom In</span>
      </Styled.ZoomButton>
      <Styled.ZoomButton
        data-tooltip-id="info-tooltip"
        data-tooltip-content="Zoom out"
        data-tooltip-place="top"
        onClick={tryZoomOut}
      >
        <IconMinus aria-hidden="true"></IconMinus>
        <span className="sr-only">Zoom Out</span>
      </Styled.ZoomButton>
      <Styled.ZoomNumber>
        <span className="sr-only">Current zoom level: </span>
        <span>{zoomPercentage}%</span>
      </Styled.ZoomNumber>

      <Styled.ZoomButton
        onClick={() =>
          resetZoom(props.cy, props.ZOOM_SETTINGS, props.selectedOptionsLength ? 'fit' : 'initial')
        }
        style={{ fontSize: '12px', textTransform: 'uppercase' }}
      >
        Reset
      </Styled.ZoomButton>
    </Styled.ZoomContainer>
  );
};

export default ZoomControls;
