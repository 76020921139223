import React, { useState } from 'react';

import SEO from '../components/SEO';

import { contentFetch } from '../lib/content-fetcher';

import PageNetworkMapLayout from '../components/NewNetwork/PageNetworkMapLayout';
import MapView from '../components/NewNetwork/MapView';

const NetworkMapPage = () => {
  const [userData, setUserData] = useState(null);

  const onMeUserDataLoaded = async (userData) => {
    const userId = userData.id;
    if (!userId) {
      return;
    }
    const userInfo = await contentFetch({
      pathname: `/users/${userId}`,
    });

    setUserData({ id: userInfo.id, member: userInfo.member, published: userInfo.publishedAt });
  };

  React.useEffect(async () => {
    contentFetch({
      pathname: '/users/me?populate=*',
      setState: onMeUserDataLoaded,
    });
  }, []);

  return (
    <>
      <SEO title="Network — Map"></SEO>
      <PageNetworkMapLayout
        pageType="map"
        CurrentView={MapView}
        userData={userData}
      ></PageNetworkMapLayout>
    </>
  );
};

export default NetworkMapPage;
